<template>
    <div class="FormBox">
        <van-form  @submit="onSubmit" :show-error='false' ref="Form" :scroll-to-error='true'>
            <div class="EventBox" >
                <h4 class="FormBoxTitle">防护装备预约</h4>
             
                <div class="BoxRow">
                    <h4>装备名称、规格、数量</h4>
                    <van-field v-model="TestValue.LA_EquipmentName"  placeholder="装备名称、规格、数量" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '装备名称、规格、数量' }]"
                    />
                </div>
                <div class="BoxRow">
                    <h4>特别要求</h4>
                    <van-field v-model="TestValue.LA_SpecialRequirements"  placeholder="特别要求" 
                    class=""
                     type="textarea"
                     autosize
                     rows="4"
                    :rules="[{ required: true, message: '特别要求' }]"
                    />
                </div>
                <div class="BoxRow tpsBox">
                    <h4 class="Tips">防护装备预约说明</h4>
                    <p>1)、使用单位预约要求的防护装备需要临时采购的，应在预约响应时明确告知使用单位，并约定反馈采购结果的期限。实验室对使用单位预约要求的防护装备无法满足的，应事先告知使用单位，以免影响实验教学。</p>
                   
                </div>
            </div>
        </van-form>
        <div class="InfButtom van-hairline--top">
            <van-button  type="primary" block @click="ClickYuyue">立即预约</van-button>
        </div>
    </div>
</template>
<script>
import { Form,CellGroup,Field,Button ,Icon ,Toast  } from 'vant';
import {Api_YyMAALaboratoryAppointment_AddEditInfo} from '@/api/laboratory'
export default {
    model:{
        prop: 'TestValue',
        event: 'ModelChange'
    },
    props:{
        TestValue:{
            type:Object
        }
    },
    components:{
       
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Button.name]: Button,
        [Icon.name]: Icon,
        [Toast.name]: Toast,
    },
    data() {
        return {
          Instrument:[1,],
           value:'',
        }
    },
    methods:{
        ClickYuyue(){
            this.$refs.Form.submit()
        },
        onSubmit(){
            Api_YyMAALaboratoryAppointment_AddEditInfo(this.TestValue).then(res=>{
              
                this.$toast('预约成功')
                if(this.$route.query.fromPath) {
                    this.$router.push(decodeURI(this.$route.query.fromPath))
                }
            }).catch(err=>{
                console.log(err)
                this.$toast(err.msg)
                // this.$router.push('/home')
                
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.FormBox {
    flex: 1;
    display: flex;
    height: calc(100vh - 46px);
    flex-direction: column;
    .van-form {
        flex: 1;
        padding-bottom: 10px;
        overflow-y: auto;
    }
    .EventBox {
        padding-top: 10px;
        background: #f2f2f2;
        .FormBoxTitle {
            font-size: 14px;
            background: #fff;
            padding: 8px 14px;
            color: #333;
        }  
    
    }
    .BoxRow {
        background: #fff;
        margin-top: 8px;
        h4 {
            font-size: 14px;
            color: #333;
            padding: 0px 14px;
            padding-top: 10px;
        }
        /deep/ .van-field {
            padding: 10px 10px;
        }
        /deep/ .van-field__body {
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            padding:5px;
        }
        p {
            font-size: 13px;
            color: #ee0a24;
            padding: 0 14px;
            line-height: 22px;
        }
        .Tips {
            padding-bottom: 10px;
        }
        
    }
    .tpsBox {
        padding-bottom: 10px;
    }
}
.InfButtom {
    padding: 10px 14px;
    background-color: #fff;

    .van-button {
        line-height: 38px;
        height: 38px;
    }
}
</style>